<template>
  <div>
    <BHeader />
    <div class="lg:pt-32 pt-12 flex flex-col justify-center items-center">
      <h3
        class="
          lg:text-4xl
          px-4
          text-xl
          font-bold
          text-center text-text-alternate-1
        "
      >
        Blessmi Advisory Board
      </h3>
      <p class="text-sm text-center text-text-alternate-1 mt-3 px-4">
        Blessmi’s Advisory Board acts as the conscience of our platform. Guides
        and Regulates our actions.
      </p>
      <hr style="color: #d7d7d7" class="mt-8 mx-auto w-10/12" />

      <div class="lg:flex justify-center p-4 lg:w-10/12 mt-12">
        <img
          src="../assets/images/Aisha.jpeg"
          class="mx-auto rounded-full h-60"
          alt=""
        />

        <div class="lg:ml-10 w-auto">
          <h4
            class="
              text-text-alternate-1 text-center
              lg:text-left
              text-xl
              mb-2
              font-bold
              mt-4
              lg:mt-0
            "
          >
            Aisha Augie
          </h4>
          <span class="flex justify-center lg:justify-start items-center">
            <p class="text-sm text-text-alternate-1 font-semibold">Member</p>
            <a target="_blank" href="https://www.linkedin.com/in/aishaak49">
              <LinkedInIn class="ml-3" />
            </a>
          </span>
          <p class="text-sm mt-6 text-text-alternate-1">
            Aisha Augie is an internationally celebrated Creative Artist and
            Communication Strategist, well known for her award-winning
            photography over the years.
          </p>
          <p class="text-sm mt-6 text-text-alternate-1">
            She is multifaceted, has vast experience in various industries in
            the private sector and public service. In public service, Aisha has
            served as a Special Adviser to the Nigerian Minister of Finance,
            Budget, and National planning on Digital communications Strategy and
            as Senior Special Assistant on New Media to the Governor of Kebbi
            state.
          </p>
          <p class="text-sm mt-6 text-text-alternate-1">
            She is a bridge builder between sectors and engages in development
            initiatives to support youths and women empowerment across Africa.
          </p>
        </div>
      </div>
      <div class="lg:flex justify-center p-4 lg:w-10/12 mt-12">
        <img
          src="../assets/images/Ekpen.png"
          class="mx-auto rounded-full h-60"
          alt=""
        />

        <div class="lg:ml-10 w-auto">
          <h4
            class="
              text-text-alternate-1 text-center
              lg:text-left
              text-xl
              mb-2
              font-bold
              mt-4
              lg:mt-0
            "
          >
            Dr. Ekpen J. Omonbude
          </h4>
          <span class="flex justify-center lg:justify-start items-center">
            <p class="text-sm text-text-alternate-1 font-semibold">Member</p>
            <a
              target="_blank"
              href="https://www.linkedin.com/mwlite/in/ekpen-j-omonbude-3435a99"
            >
              <LinkedInIn class="ml-3" />
            </a>
          </span>
          <p class="text-sm mt-6 text-text-alternate-1">
            Ekpen is an energy and mineral resources economist with over two
            decades of experience in policy and regulation, market fundamentals,
            strategy, commercial analysis, and investment decision-making. His
            experience spans international oil and gas companies, banks and
            private equity funds, national and sub-national governments,
            government agencies and regulatory institutions, policy think tanks,
            as well as the academia in over 20 countries across Latin America,
            Europe, sub-Saharan Africa, Asia and the Pacific.
          </p>
          <p class="text-sm mt-6 text-text-alternate-1">
            Ekpen is co-founder and Chair, Advisory Board, of Bargate Advisory
            Limited, a UK-based energy and mining consultancy that provides
            independent analysis, data and applications, policy advice, and
            transaction support. He is also a Board member at the multi-firm
            partnership Sustain-Alliance, and was previously Economic Adviser,
            Natural Resources at the Commonwealth Secretariat, London, UK.
          </p>
        </div>
      </div>
      <div class="lg:flex justify-center p-4 lg:w-10/12 mt-12">
        <img
          src="../assets/images/Aruodo.png"
          class="mx-auto rounded-full h-60"
          alt=""
        />

        <div class="lg:ml-10 w-auto">
          <h4
            class="
              text-text-alternate-1 text-center
              lg:text-left
              text-xl
              mb-2
              font-bold
              mt-4
              lg:mt-0
            "
          >
            Aruodo Nnenna Ofonagoro
          </h4>
          <span class="flex justify-center lg:justify-start items-center">
            <p class="text-sm text-text-alternate-1 font-semibold">Member</p>
            <a target="_blank" href="https://www.linkedin.com/in/aruodo/">
              <LinkedInIn class="ml-3" />
            </a>
          </span>
          <p class="text-sm mt-6 text-text-alternate-1">
            Aruodo is a Senior Manager and currently serves as the Head,
            Relationship Management at the Central Bank of Nigeria ITI. Through
            collaborations with leading global institutions, she and her team
            develop and manage the Institute’s training programmes based on
            identified skill gaps in the Bank and the financial sector.
          </p>
          <p class="text-sm mt-6 text-text-alternate-1">
            She is skilled in Instructional Systems Design (ISD) and
            facilitating and is also a certified Performance Management
            Consultant (PMC). In the last 19 years, she has gained significant
            experience in human capacity development and organisational
            operation’s management in various sectors. Aruodo holds a first
            degree in Criminology and Sociology and a Master’s degree in
            Criminology and Criminal Justice, both from the University of Wales,
            Cardiff, UK.
          </p>
        </div>
      </div>
      <div class="lg:flex justify-center p-4 lg:w-10/12 mt-12">
        <img
          src="../assets/images/aderike.jpeg"
          class="mx-auto rounded-full h-60"
          alt=""
        />

        <div class="lg:ml-10 w-auto">
          <h4
            class="
              text-text-alternate-1 text-center
              lg:text-left
              text-xl
              mb-2
              mt-4
              lg:mt-0
              font-bold
            "
          >
            Aderike Shonekan
          </h4>
          <span class="flex justify-center lg:justify-start items-center">
            <p class="text-sm text-text-alternate-1 font-semibold">Member</p>
            <LinkedInIn class="ml-3" />
          </span>
          <p class="text-sm mt-6 text-text-alternate-1">
            Aderike is Head of Corporate Services at Utilita Energy Limited. A
            lawyer by profession; she has experience in different sectors
            including Insurance, Banking, Communications, and Public Service.
          </p>
          <p class="text-sm mt-6 text-text-alternate-1">
            With a wealth of experience in humanitarian and development
            initiatives in Nigeria and West Africa, Aderike is a dedicated
            humanitarian with a strong passion for helping others and providing
            professional mentoring and support to women and youth in business.
          </p>
          <p class="text-sm mt-6 text-text-alternate-1">
            Aderike brings a wealth of experience to Blessmi Advisory Board.
          </p>
        </div>
      </div>

      <div class="lg:flex justify-center p-4 lg:w-10/12 mt-12">
        <img
          src="../assets/images/Adegbe.jpeg"
          class="mx-auto rounded-full h-60"
          alt=""
        />

        <div class="lg:ml-10 w-auto">
          <h4
            class="
              text-text-alternate-1 text-center
              lg:text-left
              text-xl
              mb-2
              font-bold
              mt-4
              lg:mt-0
            "
          >
            Adegbe Ehigie Ogbeh LL.B
          </h4>
          <span class="flex justify-center lg:justify-start items-center">
            <p class="text-sm text-text-alternate-1 font-semibold">Member</p>
            <a target="_blank" href="https://lnkd.in/g6DCBDW">
              <LinkedInIn class="ml-3" />
            </a>
          </span>
          <p class="text-sm mt-6 text-text-alternate-1">
            Adegbe Ogbeh is a Lawyer and dedicated serial entrepreneur with over
            20 years’ experience founding, co-founding, and managing various
            business enterprises. His career experience spans diverse industries
            such as Telecoms, Technology, Fitness, Aviation, and Hospitality.
          </p>
          <p class="text-sm mt-6 text-text-alternate-1">
            Adegbe has acquired experience and particular expertise in brand
            creation and development. He has also acted in an advisory capacity
            to numerous start-ups and is an avid social investor.
          </p>
          <p class="text-sm mt-6 text-text-alternate-1">
            Adegbe is currently founder and co-founder of a number of companies,
            notable amongst which include; Loungeone, Paper Hospitality, Ryde
            Fitness, 3AM Media, and Roombox.
          </p>
        </div>
      </div>
    </div>
    
  </div>
  <BFooterLG class="hidden mb-16 lg:block" />
</template>

<script>
import BHeader from '../components/ui/BHeader.vue';
import BFooterLG from '../components/ui/BFooterLG.vue';
export default {
  components: { BHeader, BFooterLG },
};
</script>

<style>
@media only screen and (max-width: 600px) {
  img {
    height: 80px;
  }
}
</style>
